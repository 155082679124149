import React, { useState } from "react";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Home.css";
import Result from "../Result/Result";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "../../utils/axios";
import { useParams } from 'react-router-dom';
import PublicResultComponent from "../Result/publicResultComponent";
const { Configuration, OpenAIApi } = require("openai");


function PublicResult() {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });

  const openai = new OpenAIApi(configuration);
  const [bdData, setBdData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [marketing, setMarketing] = useState([]);
  const [goals, setGoals] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [softwareCompanies, setSoftwareCompanies] = useState([]);
  const [token, setToken] = useState([]);
  const [name, setName] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const newToken = params.get("name");

  const { orgName } = useParams();

  const agencyName = params.get("agencyName");
  const agencySize = params.get("agencySize");
  const city = params.get("city");
  const state = params.get("state");
  const nonProfitName = params.get("type");
  const frameWorksList = params.get("frameWorks");
  const check = "false";
  const user = {
    agencyName: agencyName,
    city: city,
    state: state,
  };

  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  // const querry = `Create business checklist for a company of ${15} employees providing ${Web - development} services using ${DrupalMagneto} frameworks working in the ${Healthcare - Education} industries.`

  const createResult = async (user) => {
    setUserData(user);
    setLoading(true);

    setLoading(false);
    reset();
  };

  const localURL = 'http://localhost:3001';
  const serverURL = 'https://api.yournonprofitresources.com';

  useEffect(() => {
    async function fetchData() {
      const newToken = orgName;
      setToken(newToken);
      const result = await axios.post(
        `${serverURL}/api/results/fetchResult`,
        { token: newToken }
      );
      setChallenges(result.data.data[0].challenges);
      setIdeas(result.data.data[0].ideas);
      setMarketing(result.data.data[0].marketing);
      setWebsites(result.data.data[0].websites);
      setSoftwareCompanies(result.data.data[0].software_companies);
      setName(result.data.data[0].name);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <div className="rt-home mt-5">
        {challenges.length === 0 ? (
          <h4>
            {/* Generating result...
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            /> */}
          </h4>
        ) : (
          <PublicResultComponent
            marketing={marketing}
            goals={goals}
            challenges={challenges}
            ideas={ideas}
            websites={websites}
            softwareCompanies={softwareCompanies}
            LinkGeneration={check}
            name={name}
          ></PublicResultComponent>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PublicResult;
