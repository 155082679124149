/* eslint-disable eqeqeq */
import React, { createRef } from "react";
import { Col, Form, Row, Button, Spinner, Toast } from "react-bootstrap";
import "./Result.css";
import axios from "../../utils/axios";
import { blue } from "@material-ui/core/colors";

const ref = createRef();

const Result = ({
  marketing,
  challenges,
  ideas,
  websites,
  softwareCompanies,
  token,
  name,
}) => {
  function splitLink(data) {
    for (var i = 0; i < data?.length; i++) {
      if (!data[i].includes("http") && !data[i].includes("www.")) {
        data[i] = data[i] + "https://google.com";
      } else if (data[i].includes("( https://www.")) {
        data[i] = data[i].replace("( https://www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("https://www.")) {
        data[i] = data[i].replace("https://www.", "https://");
      } else if (data[i].includes("(https://www.")) {
        data[i] = data[i].replace("(https://www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("http://www.")) {
        data[i] = data[i].replace("http://www.", "https://");
      } else if (data[i].includes("(http://www.")) {
        data[i] = data[i].replace("(http://www.", "https://");
      } else if (data[i].includes("(https")) {
        data[i] = data[i].replace("(https", "https");
      } else if (data[i].includes("(http")) {
        data[i] = data[i].replace("(http", "https");
      } else if (data[i].includes("www.")) {
        data[i] = data[i].replace("www.", "https://");
      } else if (data[i].includes("( www.")) {
        data[i] = data[i].replace("( www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("(www.")) {
        data[i] = data[i].replace("(www.", "https://");
        data[i] = data[i].replace(")", "");
      }
    }
    return data;
  }

  const formatedIdeas = ideas?.split(/\d+\./);
  const splittedIdeas = splitLink(formatedIdeas);
  const formatedChallenges = challenges?.split(/\d+\./);
  const formatedMarktingData = marketing?.split(/\d+\./);
  const formatedWebsite = websites?.split(/\d+\./);
  const splittedWebsites = splitLink(formatedWebsite);
  const formattedSoftwareCompanies = softwareCompanies?.split(/\d+\./);
  const splittedCompanies = splitLink(formattedSoftwareCompanies);
  const companyName = name;

  const copyLink = () => {
    const link = document.getElementById("link").innerHTML;
    navigator.clipboard.writeText(link);
    const element = document.getElementById("success");
    element.innerHTML = "Link is copied to clipboard";
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={7} style={{ marginLeft: "10px" }}>
          <Form className="my-3 bg-light pb-3 shadow mb-3 rounded" style={{height:'68vh'}}>
            <div ref={ref} style={{ height: "auto" }}>
              <div
                className="mb-4 p-4"
                style={{ height: "68vh", overflowY: "scroll" }}
              >
                <h1
                  style={{
                    marginLeft: "20px",
                    marginBottom: "40px",
                    color: "#1F5014",
                  }}
                >
                  '{companyName}'s Plan in 2023
                </h1>
                <div className="article-title">
                  <h5>Challenges for {name} </h5>
                </div>
                {formatedChallenges?.slice(1, 6).map((item, index) => (
                  <p key={item} className="paragraph-text">
                    {index + 1}. {item}
                  </p>
                ))}

                <h5 style={{ marginLeft: "20px" }}>
                  Solutions for {companyName} in 2023
                </h5>

                <div className="article-title">
                  <h5>Ideas for {companyName} fundraising and resources</h5>
                </div>
                {splittedIdeas ? (
                  splittedIdeas?.slice(1, 11).map((item, index) => (
                    <p key={item} className="paragraph-text">
                      {index + 1}. {item.substring(0, item.indexOf("https"))}
                      <a href={item.substring(item.indexOf("https"))}>
                        {item.substring(item.indexOf("https")).split("/")[2]}
                      </a>
                    </p>
                  ))
                ) : (
                  <h4>
                    Generating...
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </h4>
                )}
                <div className="article-title">
                  <h5>Marketing ideas for {companyName} and resources</h5>
                </div>
                {formatedMarktingData ? (
                  formatedMarktingData?.slice(1, 11).map((item, index) => (
                    <p key={item} className="paragraph-text">
                      {index + 1}. {item}
                    </p>
                  ))
                ) : (
                  <h4>
                    Generating...
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="green"
                    />
                  </h4>
                )}
                <div className="article-title">
                  <h5>
                    Fundraising option for {companyName} and where to apply
                  </h5>
                </div>
                {splittedWebsites ? (
                  splittedWebsites?.slice(1, 11).map((item, index) => (
                    <p key={item} className="paragraph-text">
                      {index + 1}. {item.substring(0, item.indexOf("https"))}
                      <a href={item.substring(item.indexOf("https"))}>
                        {item.substring(item.indexOf("https")).split("/")[2]}
                      </a>
                    </p>
                  ))
                ) : (
                  <h4>
                    Generating...
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </h4>
                )}
                <div className="article-title">
                  <h5>
                    Top Web and Software Development Company For {companyName}
                  </h5>
                </div>
                {splittedCompanies ? (
                  splittedCompanies?.slice(1, 6).map((item, index) => (
                    <p key={item} className="paragraph-text">
                      {index + 1}. {item.substring(0, item.indexOf("https"))}
                      <a href={item.substring(item.indexOf("https"))}>
                        {item.substring(item.indexOf("https")).split("/")[2]}
                      </a>
                    </p>
                  ))
                ) : (
                  <h4>
                    Generating...
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </h4>
                )}
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Result;
