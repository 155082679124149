/* eslint-disable eqeqeq */
import React, { createRef } from "react";
import { Col, Form, Row, Button, Spinner, Toast } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./Result.css";
import axios from "../../utils/axios";
import moment from "moment";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pdf from "react-to-pdf";
const ref = createRef();

const Result = ({
  userData,
  marketing,
  goals,
  challenges,
  ideas,
  websites,
  softwareCompanies,
  publicResultLink,
  LinkGeneration,
  prompt1,
  prompt2,
  prompt3,
  prompt4,
  prompt5,
  shortUrl,
  token,
  domainName,
}) => {
  console.log("DOmain: ", domainName);
  function splitLink(data) {
    for (var i = 0; i < data?.length; i++) {
      if (!data[i].includes("http") && !data[i].includes("www.")) {
        data[i] = data[i] + "https://google.com";
      } else if (data[i].includes("( https://www.")) {
        data[i] = data[i].replace("( https://www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("https://www.")) {
        data[i] = data[i].replace("https://www.", "https://");
      } else if (data[i].includes("(https://www.")) {
        data[i] = data[i].replace("(https://www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("http://www.")) {
        data[i] = data[i].replace("http://www.", "https://");
      } else if (data[i].includes("(http://www.")) {
        data[i] = data[i].replace("(http://www.", "https://");
      } else if (data[i].includes("(https")) {
        data[i] = data[i].replace("(https", "https");
      } else if (data[i].includes("(http")) {
        data[i] = data[i].replace("(http", "https");
      } else if (data[i].includes("www.")) {
        data[i] = data[i].replace("www.", "https://");
      } else if (data[i].includes("( www.")) {
        data[i] = data[i].replace("( www.", "https://");
        data[i] = data[i].replace(")", "");
      } else if (data[i].includes("(www.")) {
        data[i] = data[i].replace("(www.", "https://");
        data[i] = data[i].replace(")", "");
      }
    }
    return data;
  }

  const formatedIdeas = ideas[0]?.text.split(/\d+\./);
  const splittedIdeas = splitLink(formatedIdeas);
  const formatedChallenges = challenges[0].text.split(/\d+\./);
  const formatedMarktingData = marketing[0]?.text.split(/\d+\./);
  const formatedWebsite = websites[0]?.text.split(/\d+\./);
  const splittedWebsites = splitLink(formatedWebsite);
  const formattedSoftwareCompanies = softwareCompanies[0]?.text.split(/\d+\./);
  const splittedCompanies = splitLink(formattedSoftwareCompanies);
  const personalizedLink = publicResultLink;
  const LinkGenerationNeeded = LinkGeneration;
  const query1 = prompt1;
  const query2 = prompt2;
  const query3 = prompt3;
  const query4 = prompt4;
  const query5 = prompt5;
  const shortLink = shortUrl;
  const customToken = token;

  const [toaster, setToaster] = useState(false);

  const copyLink = () => {
    const link = document.getElementById("link").innerHTML;
    navigator.clipboard.writeText(link);
    const element = document.getElementById("success");
    element.innerHTML = "Link is copied to clipboard";
  };

  const localURL = "http://localhost:3001";
  const serverURL = "https://api.yournonprofitresources.com";

  if (formattedSoftwareCompanies !== undefined) {
    const result = axios
      .post(`${serverURL}/api/results/saveResult`, {
        name: userData?.agencyName,
        ideas: ideas[0]?.text,
        challenges: challenges[0].text,
        marketing: marketing[0]?.text,
        websites: websites[0]?.text,
        software_companies: softwareCompanies[0]?.text,
        token: customToken,
        domainName: domainName,
      })
      .then((response) => {
        console.log(response.data);
        toast("The result was saved successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const queryUrl = `/query?name=${customToken}`;

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={7} style={{ marginLeft: "10px"}}>
          <Form className="my-3 bg-light pb-3 shadow mb-3 rounded" style={{height:'72vh'}}>
            <div ref={ref} style={{ height: "auto" }}>
              <div
                className="mb-4 p-4"
                style={{ height: "72vh", overflowY: "scroll" }}
              >
                <div className="mb-4 p-4">
                  <h1
                    style={{
                      marginLeft: "20px",
                      marginBottom: "40px",
                      color: "#1F5014",
                    }}
                  >
                    {userData?.agencyName} Plan in 2023
                  </h1>
                  <div className="article-title">
                    <h5>Challenges for {userData?.agencyName} </h5>
                  </div>
                  {formatedChallenges?.slice(1, 11).map((item, index) => (
                    <p key={item} className="paragraph-text">
                      {index + 1}. {item}
                    </p>
                  ))}

                  <h5 style={{ marginLeft: "20px" }}>
                    Solutions for {userData?.agencyName} in 2023
                  </h5>
                  <div className="article-title">
                    <h5>
                      Ideas for {userData?.agencyName} fundraising and Resources
                    </h5>
                  </div>
                  {splittedIdeas ? (
                    splittedIdeas?.slice(1, 11).map((item, index) => (
                      <p key={item} className="paragraph-text">
                        {index + 1}. {item.substring(0, item.indexOf("https"))}
                        <a href={item.substring(item.indexOf("https"))}>
                          {item.substring(item.indexOf("https")).split("/")[2]}
                        </a>
                      </p>
                    ))
                  ) : (
                    <h4>
                      Generating...
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </h4>
                  )}
                  <div className="article-title">
                    <h5>
                      Marketing ideas for {userData?.agencyName} and Resources
                    </h5>
                  </div>
                  {formatedMarktingData ? (
                    formatedMarktingData?.slice(1, 11).map((item, index) => (
                      <p key={item} className="paragraph-text">
                        {index + 1}.{item}
                      </p>
                    ))
                  ) : (
                    <h4>
                      Generating...
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </h4>
                  )}
                  <div className="article-title">
                    <h5>
                      Fundraising Option For {userData?.agencyName} and Where to
                      Apply
                    </h5>
                  </div>
                  {splittedWebsites ? (
                    splittedWebsites?.slice(1, 11).map((item, index) => (
                      <p key={item} className="paragraph-text">
                        {index + 1}. {item.substring(0, item.indexOf("https"))}
                        <a href={item.substring(item.indexOf("https"))}>
                          {item.substring(item.indexOf("https")).split("/")[2]}
                        </a>
                      </p>
                    ))
                  ) : (
                    <h4>
                      Generating...
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </h4>
                  )}
                  <div className="article-title">
                    <h5>
                      Top Web and Software Development Company For{" "}
                      {userData?.agencyName}
                    </h5>
                  </div>
                  {splittedCompanies ? (
                    splittedCompanies?.slice(1, 6).map((item, index) => (
                      <p key={item} className="paragraph-text">
                        {index + 1}. {item.substring(0, item.indexOf("https"))}
                        <a href={item.substring(item.indexOf("https"))}>
                          {item.substring(item.indexOf("https")).split("/")[2]}
                        </a>
                      </p>
                    ))
                  ) : (
                    <h4>
                      Generating...
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </h4>
                  )}
                  {personalizedLink ? (
                    <div>
                      <h6 style={{ marginLeft: "20px" }}>
                        Personalized url for {userData?.agencyName}{" "}
                      </h6>
                      <ToastContainer
                        position="top-right"
                        autoClose={500000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      <Link
                        id="link"
                        to={shortLink}
                        style={{
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          marginLeft: "20px",
                        }}
                      >{`https://yournonprofitresources.com${shortLink}`}</Link>
                      <div style={{ margin: "10px" }}>
                        <Button
                          id="copy-button"
                          variant="outline-success"
                          onClick={copyLink}
                          style={{ margin: "10px" }}
                        >
                          Copy Link
                        </Button>
                        <Link to={queryUrl} target="_blank">
                          <Button
                            variant="outline-success"
                            style={{ margin: "10px" }}
                          >
                            Check Queries
                          </Button>
                        </Link>
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </div>
                      <p id="success" style={{ color: "green" }}></p>
                    </div>
                  ) : (
                    <h4></h4>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Result;
