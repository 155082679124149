/* eslint-disable eqeqeq */
import React, { createRef, useState } from "react";
import { Col, Form, Row, Button, Spinner, Toast } from "react-bootstrap";
import "./Result.css";
import axios from "../../utils/axios";
import { blue } from "@material-ui/core/colors";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const ref = createRef();

const Query = (props) => {
  const [token, setToken] = useState();
  const [query1, setQuery1] = useState();
  const [query2, setQuery2] = useState();
  const [query3, setQuery3] = useState();
  const [query4, setQuery4] = useState();
  const [query5, setQuery5] = useState();
  const [name, setName] = useState();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const newToken = params.get("name");

  const localURL = 'http://localhost:3001';
  const serverURL = 'https://api.yournonprofitresources.com';

  useEffect(() => {
    async function fetchData() {
      const newToken = params.get("name");
      setToken(newToken);
      const result = await axios.post(
        `https://api.yournonprofitresources.com/api/results/fetchQuery`,
        { token: newToken }
      );
      setQuery1(result.data.data[0].first_prompt);
      setQuery2(result.data.data[0].second_prompt);
      setQuery3(result.data.data[0].third_prompt);
      setQuery4(result.data.data[0].fourth_prompt);
      setQuery5(result.data.data[0].fifth_prompt);
      setName(result.data.data[0].name);
    }
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Row className="justify-content-md-center">
        <Col md={7} style={{ marginLeft: "10px" }}>
          <Form className="my-3 bg-light pb-3 shadow mb-3 rounded">
            <div ref={ref} style={{ height: "auto" }}>
              <div
                className="mb-4 p-4"
                style={{ height: "400px", overflowY: "scroll", marginTop:'100px' }}
              >
                <div className="article-title">
                  <h5>Queries for {name} </h5>
                </div>
                <div style={{marginLeft:'20px'}}>
                  <p>1. {query1}</p>
                  <p>2. {query2}</p>
                  <p>3. {query3}</p>
                  <p>4. {query4}</p>
                  <p>5. {query5}</p>
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Query;
